import React from "react";
import { Flex, Typography } from "antd";
import { Table } from "antd";
import styles from "./styles.module.css";
import SectionMarker from "../../../components/section-marker/SectionMarker";
import { AlignType } from 'rc-table/lib/interface';

interface DataType {
    key: React.Key;
    tariffs: string;
    price: string;
    opensNums: string;
    opensPrice: string;
}

const columns: TableColumnsType<DataType> = [
    { title: "Тариф*", dataIndex: "tariffs", key: "tariffs", align: "center" },
    { title: "Стоимость в месяц", dataIndex: "price", key: "price", align: "center" },
    { title: "Количество открытий**", dataIndex: "opensNums", key: "opensNums", align: "center" },
    { title: "Стоимость одного открытия**", dataIndex: "opensPrice", key: "opensPrice", align: "center" },
];

const data: DataType[] = [
    {
        key: 1,
        tariffs: "A+",
        price: "250 000 руб.",
        opensNums: "Без ограничений",
        opensPrice: "-",
    },
    {
        key: 2,
        tariffs: "A",
        price: "161 000 руб.",
        opensNums: "70 000",
        opensPrice: "2.3 руб.",
    },
    {
        key: 3,
        tariffs: "B",
        price: "80 000 руб.",
        opensNums: "20 000",
        opensPrice: "4 руб.",
    },
    {
        key: 4,
        tariffs: "C",
        price: "30 000 руб.",
        opensNums: "6 000",
        opensPrice: "5 руб.",
    },
    {
        key: 5,
        tariffs: "D",
        price: "12 000 руб.",
        opensNums: "1 000",
        opensPrice: "12 руб.",
    },
];

const TariffsSection = () => {
    return (
        <section className={styles["tariffs-section"]}>
            <Flex className={styles["tariffs-section-content"]}>
                <SectionMarker
                    style={{
                        position: "absolute",
                        left: "-20px",
                    }}
                />

                <Typography.Title
                    className={styles["tariffs-section-title"]}
                    style={{
                        fontWeight: "400",
                        textAlign: "center",
                        marginBottom: 0,
                    }}
                >
                    Тарифы
                </Typography.Title>
            </Flex>

            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Typography
                style={{
                    fontWeight: "400",
                    marginTop: "20px",
                }}
            >
                *Тарифы действуют с 01.01.2025
            </Typography>

            <Typography
                style={{
                    fontWeight: "400",
                    marginTop: "10px",
                }}
            >
                **Тарифицируется только въезд на закрытую территорию при условии, что выезд будет совершен не позднее 40 минут после въезда
            </Typography>
        </section>
    );
};

export default TariffsSection;