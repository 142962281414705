import {Flex, Typography, Spin} from "antd";
import SectionMarker from "../../../components/section-marker/SectionMarker";
import StatInformer from "../../../components/stat-informer/StatInformer";
import styles from "./styles.module.css";
import {useCallback, useEffect, useState} from "react";
import {TPS_MAP_URL} from "../../../config";
import MapImage from "../assets/map.jpg"

function buildTPSMapURL(userLocation) {
    return `${TPS_MAP_URL}?lat=${userLocation.latitude}&lon=${userLocation.longitude}`
}

const MapSection = () => {

    const [userLocation, setUserLocation] = useState(undefined);

    useEffect(() => {
        const getUserLocation = () => {
            const defaultLocation = {
                latitude: 55.755826,
                longitude: 37.6173
            } // Moscow
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const {latitude, longitude} = position.coords;
                        setUserLocation({latitude, longitude});
                    },
                    (error) => {
                        console.error('Error getting user location:', error);
                        setUserLocation(defaultLocation);
                    }
                );
            } else {
                setUserLocation(defaultLocation);
            }
        };
        getUserLocation();
    }, [])

    const renderTPSMap = useCallback(() => {
        if (userLocation) {
            return (
                <>
                    <iframe title="tps" src={buildTPSMapURL(userLocation)}
                            style={{
                                width: "100%",
                                height: "600px",
                                borderRadius: "10px",
                                overflow: "hidden",
                            }}>
                    </iframe>
                    <a
                        className={styles["map-section-link"]}
                        href={buildTPSMapURL(userLocation)}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        На весь экран
                    </a>
                </>
            )
        } else {
            return (
                <Flex align="center" gap="middle"
                      style={{
                          position: "relative"
                      }}
                >
                    <Spin size="large"
                          style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)"
                          }}/>
                    <img width="100%" src={MapImage} alt="map"/>
                </Flex>
            )
        }
    }, [userLocation])

    return (
        <section className={styles["map-section"]}>
            <Flex className={styles["map-section-header"]}>
                <SectionMarker
                    style={{
                        position: "absolute",
                        top: "45px",
                        left: "-20px",
                    }}
                />

                <Typography.Title
                    className={styles["map-section-title"]}
                    style={{
                        width: "100%",
                        fontWeight: "400",
                        marginBottom: 0,
                    }}
                >
                    Шлагбаумы TPS Gate <br/> на карте
                </Typography.Title>
            </Flex>

            <Flex
                vertical
                className={styles["map-section-description"]}
            >
                <Typography.Text
                    style={{
                        fontWeight: 400
                    }}
                >
                    На карте вы можете увидеть и проанализировать количество шлагбаумов,
                    подключённых к TPS Gate, в вашем регионе.
                </Typography.Text>
                <Typography.Text
                    style={{
                        fontWeight: 400,
                        marginTop: "16px",
                    }}
                >
                    С нашим сервисом шлагбаумы больше не будут проблемой для водителей,
                    доставок и такси. Добавьте свой шлагбаум в систему, скачав приложение
                    TPS Gate в Google Play или App Store.
                </Typography.Text>
            </Flex>


            <Flex vertical gap={20} className={styles["map-section-information"]}>
                <div
                    style={{
                        borderRadius: "10px",
                        overflow: "hidden",
                    }}
                >
                    {renderTPSMap()}
                </div>
                <Flex
                    justify="space-between"
                    gap={20}
                    className={styles["map-section-stat-information"]}
                >
                    <StatInformer info="135 000+" description="Водителей"/>
                    <StatInformer info="2 800+" description="Шлагбаумов в Москве"/>
                    <StatInformer info="50+" description="Шлагбаумов в Воронеже"/>
                    <StatInformer info="600+" description="Шлагбаумов в Беларуси"/>
                </Flex>
            </Flex>
        </section>
    );
};

export default MapSection;