import { Flex } from "antd";
import MainSection from "./main-section/MainSection";
import TariffsSection from "./tariffs-section/TariffsSection";
import ContactSection from "./contact-section/ContactSection";
import FooterSection from "./footer-section/FooterSection";

const IndexPage = () => {
    return (
        <Flex
            vertical
            gap="100px"
            style={{
                backgroundColor: "#f0f0f0",
            }}
        >
            <MainSection />
            <TariffsSection />
            <ContactSection />
            <FooterSection />
        </Flex>

    );
};

export default IndexPage;
