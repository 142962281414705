import React from "react";
import { Flex, Typography } from "antd";
import { Table } from "antd";
import styles from "./styles.module.css";
import SectionMarker from "../../../components/section-marker/SectionMarker";

const TariffsSection = () => {
    return (
        <section className={styles["tariffs-section"]}>
            <Flex className={styles["tariffs-section-content"]}>
                <SectionMarker
                    style={{
                        position: "absolute",
                        left: "-20px",
                    }}
                />

                <Typography.Title
                    className={styles["tariffs-section-title"]}
                    style={{
                        fontWeight: "400",
                        textAlign: "center",
                        marginBottom: 0,
                    }}
                >
                    Тарифы РБ
                </Typography.Title>
            </Flex>

            <Typography
                style={{
                    fontSize: "20px",
                    fontWeight: "400",
                    marginTop: "20px",
                }}
            >
                С 1 января 2025 года стоимость одного открытия шлагбаума составляет 0,7 руб. с НДС.
            </Typography>

            <Typography
                style={{
                    fontSize: "20px",
                    fontWeight: "400",
                    marginTop: "20px",
                }}
            >
                Для компаний с количеством активных водителей до 5 (включительно) стоимость услуг определяется
                путем умножения числа активных водителей, зарегистрированных в отчетном месяце, на размер
                лицензионного вознаграждения за одного водителя, установленного в размере 12 руб. без учета
                налога на добавленную стоимость за месяц.
            </Typography>
        </section>
    );
};

export default TariffsSection;