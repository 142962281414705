import {API_URL} from "../config";

export const apiRouteUrl = (route) => {
    return `${API_URL}/v1/${route}`;
};

const baseApiRequest = async (url, options) => {
    const response = await fetch(url, options);
    if (!response.ok) {
        const errorData = await response.json();
        errorData.status = response.status;
        throw errorData;
    }
    if (response.headers.get("Content-Type") === "application/json") {
        return await response.json();
    }
    return undefined;
};

const defaultFetchOptions = (method) => {
    const options = {
        method,
        headers: {},
    };

    options.headers = {};

    if (method === "POST") {
        options.headers["Content-Type"] = "application/json";
    }

    return options;
};


export const postApiRequest = (routeRelativeUrl, body) => {
    const options = defaultFetchOptions("POST");

    if (body) {
        options.body = JSON.stringify(body);
    }

    const url = apiRouteUrl(routeRelativeUrl);
    return baseApiRequest(url, options);
};

export const postContactForm = async (request) => {
    return (await postApiRequest("form/contacts", request));
};




